import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Wafer`}</h4>
    <p>{`Silicon wafers are the basic building block for chip production. To produce them, a furnace forms a cylinder of silicon (or other semiconducting materials), which is then cut into disc-shaped wafers. These wafers are then processed, split and packaged into individual chips. Most wafers are made purely of silicon or another material, but others have more complex structures.  Dopants, such as boron, aluminum, phosphorous, platinum or other elements, may be added to alter the level of semiconductivity.`}</p>
    <p>{`300 mm wafers, produced by Japanese, Taiwanese, German, and Korean firms, are used to produce nearly all advanced chips today. Manufacturing the smoothest, high-purity 300 mm wafers-which are necessary for supporting the smallest chip feature sizes-requires considerable tacit know-how. Older-technology wafer sizes include 150 and 200 mm, used for chips with larger feature sizes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      